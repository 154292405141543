import { IWixWindow, PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { GetInstagramAccountResponse } from '@wix/ambassador-instagram-feed-v1-instagram-account/types';
import { ListInstagramAccountMediaResponse } from '@wix/ambassador-instagram-feed-v1-instagram-media/types';
import {
  createExpandModeAriaLabel,
  generateErrorMessage,
  PgItemType,
  getViewerEssentials,
  LightboxService,
  getIsViewMode,
  localizeDate,
} from '.';
import { IG_URL } from '../../constants';
import { ElementRoles } from '../../enums';
import { bi } from '../../bi';
import { LightboxDataType, WidgetPropsI } from '../../types';

export const selectorFactory = ($w, flowAPI?: PlatformControllerFlowAPI) => {
  const collapseApp = () => $w(`#${ElementRoles.Widget}`).collapse();

  const hideArrows = ({
    index,
    medias,
  }: {
    index: number;
    medias: PgItemType[];
  }) => {
    const isFirst = index === 0;
    const isLast = medias.length - 1 === index;
    if (isFirst && isLast) {
      $w(`#${ElementRoles.LeftArrowBtn}`).hide();
      $w(`#${ElementRoles.RightArrowBtn}`).hide();
    } else if (isFirst) {
      $w(`#${ElementRoles.LeftArrowBtn}`).hide();
      $w(`#${ElementRoles.RightArrowBtn}`).show();
    } else if (isLast) {
      $w(`#${ElementRoles.LeftArrowBtn}`).show();
      $w(`#${ElementRoles.RightArrowBtn}`).hide();
    } else {
      $w(`#${ElementRoles.LeftArrowBtn}`).show();
      $w(`#${ElementRoles.RightArrowBtn}`).show();
    }
  };

  const setExpandModeItem = (index, medias, userName, isDemo) => {
    const { locale } = flowAPI?.controllerConfig.wixCodeApi
      .window as IWixWindow;
    const selectedItem = medias[index];
    getIsViewMode(flowAPI) && hideArrows({ index, medias });
    const isCarousel = selectedItem.type === 'carousel';
    const media = isCarousel ? selectedItem.children : [selectedItem];
    const { description, link, timestamp } = selectedItem;
    const demoLink = `${IG_URL}${
      flowAPI?.environment.isClassicEditor ? 'wix' : 'wixstudio'
    }`;
    const instagramLink = isDemo ? demoLink : link;

    $w(`#${ElementRoles.ExpandUserName}`).html = `<p>${userName}</p>`;
    $w(`#${ElementRoles.ExpandDescription}`).text = description;
    $w(`#${ElementRoles.ExpandProGallery}`).items = media;
    $w(`#${ElementRoles.ExpandProGallery}`)
      .currentIndex()
      .then((postIndex) => {
        // Reset to first post in case of album
        if (postIndex > 0) {
          $w(`#${ElementRoles.ExpandProGallery}`).navigateToIndex(0, 0);
        }
      });

    $w(`#${ElementRoles.ExpandIGBtn}`).link = instagramLink;
    flowAPI &&
      $w(`#${ElementRoles.ExpandIGBtn}`).onClick(() =>
        bi({ flowAPI }).clickOnButtonOrLink('ig_icon'),
      );

    const date = localizeDate(timestamp, locale || 'en-US');
    $w(`#${ElementRoles.ExpandDate}`).html = `<p>${date}</p>`;

    // A11y
    $w(`#${ElementRoles.ExpandWidget}`).accessibility.ariaLabel =
      createExpandModeAriaLabel(userName, timestamp);
  };

  const populateMedia = ({
    igUser,
    igMedia,
    props,
  }: {
    igUser: GetInstagramAccountResponse;
    igMedia: ListInstagramAccountMediaResponse | PgItemType[];
    props: WidgetPropsI;
  }) => {
    const { accountId, shouldLimitImages, mediasOnInitialLoad } = props;
    const isDemo = !accountId;
    $w(`#${ElementRoles.ProGallery}`).restore();
    $w(`#${ElementRoles.HeaderContainer}`).expand();
    const userName = igUser.instagramAccount?.instagramInfo?.instagramUsername;
    const { UserName, Hashtag, Title } = getInnerHeaderElements();
    UserName.label = `@${userName}`;
    flowAPI &&
      Hashtag.onClick(() => bi({ flowAPI }).clickOnButtonOrLink('ig_hashtags'));
    const demoLink = `${
      flowAPI?.environment.isClassicEditor ? 'wix' : 'wixstudio'
    }`;

    if (isDemo) {
      if (shouldLimitImages) {
        igMedia = (igMedia as PgItemType[]).slice(0, mediasOnInitialLoad);
      }

      if (flowAPI?.translations) {
        const { t } = flowAPI.translations;
        Title.text = t('instagram-blocks.widget.demo.followUsOnInstagram');
        $w(`#${ElementRoles.LoadMore}`).label = t(
          'instagram-blocks.widget.demo.loadMoreBtn',
        );
      }
    }
    $w(`#${ElementRoles.ProGallery}`).items = igMedia;
    UserName.link = `${IG_URL}${isDemo ? demoLink : userName}`;
    flowAPI &&
      UserName.onClick(() =>
        bi({ flowAPI }).clickOnButtonOrLink('ig_user_name'),
      );
  };

  const initiateElements = (props: WidgetPropsI) => {
    // Removing PG element completely in case of failure - PG element without medias
    // Restoring it on populateMedias method (When successful in getting data)
    showLoadMoreHandler(props.showLoadMore);
    $w(`#${ElementRoles.ProGallery}`).delete();
    injectEmptyStateText('', '');
  };

  const openExpandMode = ({
    pgItem,
    data,
  }: {
    pgItem: PgItemType;
    data: LightboxDataType;
  }) => {
    const { flowAPI } = getViewerEssentials();
    const { wixCodeApi } = flowAPI.controllerConfig;
    const lightboxService = new LightboxService(wixCodeApi);
    lightboxService.openExpandMode({ pgItem, data });
  };

  const getInnerHeaderElements = () => {
    const Title = $w(`#${ElementRoles.HeaderTitle}`);
    const Hashtag = $w(`#${ElementRoles.HeaderHashtagBtn}`);
    const UserName = $w(`#${ElementRoles.HeaderUserNameBtn}`);
    return { Title, Hashtag, UserName };
  };

  const generateErrorState = (error, t) => {
    const { title, description } = generateErrorMessage(error, t);
    console.log('Generating Error state!', { error, title, description });
    $w(`#${ElementRoles.HeaderContainer}`).collapse();

    const emptyStateElement = $w(`#${ElementRoles.EmptyStateWidget}`);
    emptyStateElement.expand();
    emptyStateElement.show();
    injectEmptyStateText(title, description);
  };

  const injectEmptyStateText = (title: string, description: string) => {
    const [container] = $w(`#${ElementRoles.EmptyStateWidget}`).children;
    const [titleElement, descriptionElement] = container.children;
    titleElement.text = title;
    descriptionElement.text = description;
  };

  const showLoadMoreHandler = (shouldShow: boolean) => {
    const method = shouldShow ? 'expand' : 'collapse';
    $w(`#${ElementRoles.LoadMore}`)[method]();
  };

  return {
    collapseApp,
    populateMedia,
    openExpandMode,
    setExpandModeItem,
    generateErrorState,
    initiateElements,
    showLoadMoreHandler,
  };
};
